import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { GridsterConfig, GridsterModule } from 'angular-gridster2';
import { Subject, takeUntil } from 'rxjs';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';
import { MobileBrowserChecker } from 'src/helpers/mobile-browser-checker';
import {
  ContainerStationInformation,
  EditDataWidget,
  GridsterItemWidget,
  Question,
  StationWidgetData,
  WidgetType,
} from 'src/models';
import { DashboardWidgets } from 'src/helpers/dashboard-widgets';
import { CommonModule } from '@angular/common';
import { InlineFieldUpdateComponent } from 'src/app/shared/inline-field-update/inline-field-update.component';
import { BodyTextWidgetComponent } from 'src/app/shared/station-container-widgets/body-text-widget/body-text-widget.component';
import { CircleImageWidgetComponent } from 'src/app/shared/station-container-widgets/circle-image-widget/circle-image-widget.component';
import { HeadlineWidgetComponent } from 'src/app/shared/station-container-widgets/headline-widget/headline-widget.component';
import { InputFrameWidgetComponent } from 'src/app/shared/station-container-widgets/input-frame-widget/input-frame-widget.component';
import { TitleWidgetComponent } from 'src/app/shared/station-container-widgets/title-widget/title-widget.component';
import { ToolbarImageWidgetComponent } from 'src/app/shared/station-container-widgets/toolbar-image-widget/toolbar-image-widget.component';
import { ContainerPreBuiltWidgetComponent } from 'src/app/shared/widget-dashboard/container-pre-built-widget/container-pre-built-widget.component';
import { ContainerWidgetComponent } from 'src/app/shared/widget-dashboard/container-widget/container-widget.component';
import { GroupContainerWidgetComponent } from 'src/app/shared/widget-dashboard/group-container-widget/group-container-widget.component';
import { GroupSearchWidgetComponent } from 'src/app/shared/widget-dashboard/group-search-widget/group-search-widget.component';
import { GroupTrafficWidgetComponent } from 'src/app/shared/widget-dashboard/group-traffic-widget/group-traffic-widget.component';
import { RelationshipWidgetComponent } from 'src/app/shared/widget-dashboard/relationship-widget/relationship-widget.component';
import { StationPreBuiltWidgetComponent } from 'src/app/shared/widget-dashboard/station-pre-built-widget/station-pre-built-widget.component';
import { StationWidgetComponent } from 'src/app/shared/widget-dashboard/station-widget/station-widget.component';
import { ContainerService } from 'src/app/core/container.service';
/**
 * Component for the document template area of a station/document.
 */
@Component({
  selector: 'app-container-template[documentFields][stationInstructions]',
  templateUrl: './container-template.component.html',
  styleUrls: ['./container-template.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GridsterModule,
    InlineFieldUpdateComponent,
    HeadlineWidgetComponent,
    TitleWidgetComponent,
    BodyTextWidgetComponent,
    CircleImageWidgetComponent,
    StationWidgetComponent,
    ContainerWidgetComponent,
    GroupSearchWidgetComponent,
    GroupTrafficWidgetComponent,
    StationPreBuiltWidgetComponent,
    ContainerPreBuiltWidgetComponent,
    GroupContainerWidgetComponent,
    InputFrameWidgetComponent,
    RelationshipWidgetComponent,
    ToolbarImageWidgetComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContainerTemplateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContainerTemplateComponent),
      multi: true,
    },
  ],
})
export class ContainerTemplateComponent
  implements ControlValueAccessor, Validator, OnInit, OnDestroy
{
  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** The form to add to document. */
  documentTemplateForm!: FormGroup<{
    /** Form control field documentFieldForm. */
    documentFieldForm: FormControl<string | null>;
    /** Form control field inputFrameFieldForm. */
    inputFrameFieldForm: FormControl<string | null>;
  }>;

  /** Whether the gridSter section to be displayed or not. */
  @Input() displayGridTemplate = false;

  /** The general instructions to be displayed, if any. */
  @Input() stationInstructions!: string;

  /** The document fields in the template area for the document. */
  @Input() documentFields!: Question[];

  /** Whether the document is displayed inside the widget or not. */
  @Input() isWidget = false;

  /** Relationship widget flag. */
  @Input({ required: true }) relationshipWidgetFlag = false;

  /** Flag self_assign. */
  @Input({ required: true }) showSelfAssign = true;

  /** The document id as an optional parameter. */
  @Input() containerRithmId = '';

  /** The station id as an optional parameter. */
  @Input() stationRithmId = '';

  /** Station Widgets array. */
  @Input() inputFrameWidgetItems: GridsterItemWidget[] = [];

  /** The information about the document within a station. */
  @Input() containerInformation!: ContainerStationInformation;

  /** Input to identify if exist answers pendent to save. */
  @Input() containerAnswer = false;

  /** Feature flag container station overlay. */
  @Input({ required: true }) containerStationOverlayFeature = false;

  /** Grid initial values. */
  @Input() options: GridsterConfig = {
    gridType: 'verticalFixed',
    fixedRowHeight: 50,
    displayGrid: 'none',
    pushItems: false,
    draggable: {
      enabled: false,
      ignoreContent: false,
    },
    resizable: {
      enabled: false,
    },
    margin: 12,
    minCols: 24,
    maxCols: 24,
    maxRows: 500,
    outerMargin: true,
    outerMarginLeft: 16,
    outerMarginRight: 16,
    outerMarginTop: 16,
    allowMultiLayer: true,
    defaultLayerIndex: 1,
    maxLayerIndex: 2,
    baseLayerIndex: 1,
  };

  /** Widget in frames update  flag. */
  @Input({ required: true }) showWidgetFramesUpdate = false;

  /** Feature flag for show widget header and subheader. */
  @Input({ required: true }) headerFeatureFlag = false;

  /** True if the user is architect else False. */
  @Input({ required: true }) isArchitect = false;

  /** Feature flag for the order of operations coming from the parent container. */
  @Input({ required: true }) orderOfOperations = false;

  /** Feature flag widget column update. */
  @Input({ required: true }) widgetColumnFeatureFlag = false;

  /** Feature Flag for sorting widget. */
  @Input({ required: true }) multiSortingFeatureFlag = false;

  /** Disabled all questions.*/
  @Input() disableCurrentContainer = false;

  /** Whether should disabled all question.*/
  @Input() canNotEditContainer = false;

  /** Widget id from popper js is opened. */
  widgetIdPopperIsOpened = '';

  /** Input frame widgets items copy. */
  inputFrameWidgetItemsCopy: GridsterItemWidget[] = [];

  /** The context of what is open in the drawer. */
  drawerContext = 'comments';

  /** Different types of input frames components.*/
  frameType = WidgetType;

  /** Show only button delete widget in drawer. */
  deleteWidget = false;

  /** If can assign user in the widget. */
  canAssignUserWidget = false;

  /** SelfAssign Param to station. */
  assignmentParam = false;

  constructor(
    private fb: FormBuilder,
    private sidenavDrawerService: SidenavDrawerService,
    public mobileBrowserChecker: MobileBrowserChecker,
    private containerService: ContainerService,
  ) {
    this.documentTemplateForm = this.fb.group({
      documentFieldForm: this.fb.control(''),
      inputFrameFieldForm: this.fb.control(''),
    });
  }

  /**
   * Set up Initial questions.
   */
  ngOnInit(): void {
    this.popperOpened$();
    this.inputFrameWidgetItemsCopy = JSON.parse(
      JSON.stringify(this.inputFrameWidgetItems),
    );
    if (this.documentFields) {
      this.documentFields.map((question) => {
        if (this.stationRithmId === question.originalStationRithmId) {
          question.isReadOnly = false;
        }
      });
    }
  }

  /** Subject popper opened. */
  private popperOpened$(): void {
    this.containerService.popperQuestionOpened$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((id) => {
        this.widgetIdPopperIsOpened = id;
      });
  }

  /**
   * The `onTouched` function.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  /**
   * Writes a value to this form.
   * @param val The value to be written.
   */
  // eslint-disable-next-line
  writeValue(val: any): void {
    val && this.documentTemplateForm.setValue(val, { emitEvent: false });
  }

  /**
   * Registers a function with the `onChange` event.
   * @param fn The function to register.
   */
  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.documentTemplateForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(fn);
  }

  /**
   * Registers a function with the `onTouched` event.
   * @param fn The function to register.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Sets the disabled state of this form control.
   * @param isDisabled The disabled state to set.
   */
  setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.documentTemplateForm.disable()
      : this.documentTemplateForm.enable();
  }

  /**
   * Reports whether this form control is valid.
   * @returns Validation errors, if any.
   */
  validate(): ValidationErrors | null {
    return this.documentTemplateForm.valid
      ? null
      : {
          invalidForm: {
            valid: false,
            message: 'User form is invalid',
          },
        };
  }

  /**
   * Open drawers on the dashboard.
   * @param drawerItem The information that will be displayed in the side drawer.
   * @param drawerData Data optional of the drawer.
   */
  toggleDrawer(
    drawerItem: 'widgetDashboard',
    drawerData?: EditDataWidget,
  ): void {
    this.drawerContext = drawerItem;
    this.sidenavDrawerService.toggleDrawer(drawerItem, drawerData);
  }

  /** Open drawer only button delete widget. */
  removeWidget(): void {
    this.deleteWidget = true;
  }

  /**
   * Get limit of page by rows of widget.
   * @param widget The widget for evaluate.
   * @returns The limit to list items on widget.
   */
  limitListPerPage(widget: GridsterItemWidget): number {
    return widget.rows * 2;
  }

  /**
   * Toggle drawer of the station widget.
   * @param widgetItem String of the data station.
   * @param widgetIndex Number of the position the widget.
   * @param widgetData Station widget data.
   */
  toggleWidgetDrawer(
    widgetItem: GridsterItemWidget,
    widgetIndex: number,
    widgetData: StationWidgetData | number,
  ): void {
    const contextDrawerWidget = 'widgetDashboard';
    this.toggleDrawer(contextDrawerWidget, {
      widgetItem,
      widgetIndex,
      quantityElementsWidget:
        typeof widgetData === 'number'
          ? widgetData
          : widgetData.containers.length,
      deleteWidget: this.deleteWidget,
    });
    this.deleteWidget = false;
  }

  /**
   * Validate if a widget type is station related or not.
   * @param widgetType The widget for evaluate.
   * @returns The TRUE if it's of station else FALSE.
   */
  isStationWidget(widgetType: WidgetType): boolean {
    return (
      widgetType === WidgetType.Station ||
      widgetType === WidgetType.StationTableBanner ||
      widgetType === WidgetType.StationMultiline ||
      widgetType === WidgetType.StationMultilineBanner
    );
  }

  /**
   * Validate if a widget type is container related or not.
   * @param widgetType The widget for evaluate.
   * @returns The TRUE if it's of container else FALSE.
   */
  isContainerWidget(widgetType: WidgetType): boolean {
    return (
      widgetType === WidgetType.Container ||
      widgetType === WidgetType.ContainerListBanner ||
      widgetType === WidgetType.ContainerProfileBanner
    );
  }

  /**
   * Change options in grid.
   *
   */
  changedOptions(): void {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Whether the widget is a dashboard type.
   * @param type Widget type to check.
   * @returns Boolean.
   */
  isDashboardWidget(type: WidgetType): boolean {
    return DashboardWidgets.isDashboardWidget(type);
  }
}
